@import "App";

.title {
    @include large-bold-text;
    margin: 40px 0 20px;
}

.name {
    text-transform: capitalize;
}

.filter {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: 20px;

    @include media-up($md){
        flex-direction: row; 
    }
}

.buttongold {
    @include gold-button;
}

.sortTag {
    position: absolute;
    width: 11px;
    height: 12px;
    margin: 0;
    line-height: 10px;
    padding: 0;
    right: -15px;
    top: -8px;
}
