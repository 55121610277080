div.lotline-mobile-background {
  position: absolute;
  flex-direction: row;
  top: calc(10%);
  /*right: -120px;*/
  transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
  z-index: -10;
  /*aspect-ratio: 2/1;*/
}

.awfull-m {
  width: 60%;
  height: 100%;
  transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0)
    matrix(0.71, 0.41, -1.22, 0.71, 0, 0) matrix(0.71, 0.41, -1.22, 0.71, 0, 0)
    matrix(0.71, 0.41, -1.22, 0.71, 0, 0) matrix(0.71, 0.41, -1.22, 0.71, 0, 0)
    matrix(0.71, 0.41, -1.22, 0.71, 0, 0) matrix(0.71, 0.41, -1.22, 0.71, 0, 0)
    translate(0px, -15%);
}
