@import "App";

.title {
    @include light-heading;
    color: var(--color-text-primary);
    margin-bottom: 15px;

    span {
        @include medium-heading;
    }
}

.address {
    @include light-heading3;
    color: var(--color-text-primary);
    margin-bottom: 15px;
}

.video {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;

    iframe {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
    }
}

.tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;

    &_item {
        @include extra-small-text;
        border: 1px solid $gold;
        border-radius: 22px;
        color: $gold;
        display: inline-block; 
        padding: 4px 10px 5px;
    }
}

.image {
    width: 100%;
    margin-bottom: 20px;
    display: block;
}

.modal {
    text-align: right;
}

.editModal {
    :global(.ant-form-item-label) {
        min-width: 196px;
        text-align: left;
    }
}

.btn {
    border: solid 2px var(--color-text-primary);
    color: var(--color-text-primary);
    font-size: 18px;
    padding: 7px 20px;
    cursor: pointer;

    &:hover {
        border-color: var(--color-text-primary-active);
        color: var(--color-text-primary-active);
    }
}

.delBtn {
    margin: 0 5px 0 0;

    &:hover {
        color: var(--color-text-primary-active);
    }
}

:global(.ant-upload-draggable-list-item) {
    &:global(.drop-over-upward),
    &:global(.drop-over-downward) {

        & > div {
            border-color: var(--color-text-primary-active) !important;
        }
    }
   
}