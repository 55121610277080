
@import "App";

.lotline_header {
    background: var(--background-primary-light);
    display: flex;
    min-height: 90px;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 100;

    &_content {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        padding: 15px 20px;
    }
}

.lotline_header_logo {
    display: inline-flex;
    align-items: center;
    margin-right: 50px;

    &_img {
        height: 4.4vw;  
        max-height: 20px;
        
        @include media-up($md) {
            max-height: 34px;
        }

        [data-amplify-color-mode="dark"] & {
            path:not(:last-child) {
                fill: #fff;
            }
        }
    }
}

.lotline_header_mobile_menu {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
    flex-direction: row;

    @include media-up($md) {
        display: none;
    }

    &_btn {
        background: none;
        border: none;
        color: var(--color-text-primary);
        width: 24px;
        height: 24px;
      }
}

.lotline_header_menu {
    display: none;

    @include media-up($md) {
        display: block;
        flex-grow: 1;
        max-width: calc(100% - 180px);
    }
}
