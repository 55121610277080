@import "App";

.market {
    min-height: 100px;
    label {
        color: var(--color-text-primary);
    }
}

.title {
    color: var(--color-text-primary);
    @include medium-text;
}
