@import "App";

.lotline_btn_w {
  border: solid 2px var(--color-text-primary);
  color: var(--color-text-primary);
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  align-items: start;
  white-space: nowrap;
  width: fit-content;

  @include media-up($md) {
    font-size: 14px;
  }

  .lotline_btn_inner {
    color: var(--color-text-primary);
    display: block;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    letter-spacing: .05em;
    line-height: 1.2;
    padding: 16px 24px;
    text-decoration: none;
    text-transform: uppercase;
  }
   

  &_active,
  &:not(&_disabled):hover {
    border-color: var(--color-text-primary-active);
    
    .lotline_btn_inner {
      color: var(--color-text-primary-active) !important;
    }
  }

  &_disabled {
    border-color: $grey;
    
    .lotline_btn_inner {
      color: $grey;
    }
  }

  &_small {
    font-size: 12px;
    padding: 0;

    .lotline_btn_inner {
      padding: 11px 24px;
    }
  }
}



.lotline_btn_full_w {
  font-size: 14px;
  text-align: center;
  width: 100%;

  @include media-up($md) {
    font-size: 16px;
  }

  .lotline_btn_inner {
    padding: 24px;
  }
}
  


  