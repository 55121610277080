footer.lotline-footer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  background-color: #02163d;
  align-items: stretch;
  width: 100%;
  /*max-width: 1440px;*/
  /*min-height: 373px;*/
  min-height: 25.9vw;
}

div.footer-vertical-container {
  display: inline-flex;
  flex-direction: column;
  /*border: white solid 1px;*/
  margin-top: 4.86vw;
  margin-bottom: 4.86vw;
  flex-grow: 1;
}

div.footer-row-1 {
  position: relative;
  display: inline-flex;
  flex-grow: 2;
  flex-direction: row;
  border-bottom: white solid 1px;
}

div.footer-row1-logo {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  flex-grow: 1;
}

div.footer-row1-menu {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  flex-grow: 1;
}

div.footer-row1-menu-row {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  justify-content: stretch;
  flex-grow: 1;
}

div.footer-row1-col {
  display: inline-flex;
  flex-direction: column;
  flex-grow: 1;
  color: white;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  /*font-size: 14px;*/
  font-size: 0.97vw;
  line-height: 160%;
  text-align: start;
}

div.footer-row-2 {
  /*position: relative;*/
  display: inline-flex;
  flex-direction: row;
  flex-grow: 1;
}

@media only screen and (max-width: 767.9px) {
  div.footer-row-2 {
    flex-direction: column;
  }

  div.footer-row-2-social {
    align-items: start !important;
    text-align: start !important;
  }

  div.footer-row-1 {
    flex-direction: column;
  }
  div.footer-row1-col {
    padding-top: 32px;
    font-size: 14px;
  }
  div.footer-row1-menu-row {
    flex-direction: column;
    justify-content: start;
    align-items: start;
    padding-bottom: 40px;
  }
  img.footer-logo {
    height: 18px !important;
  }
  .footer-menu-item {
    font-size: 12px !important;
  }
}

div.footer-row-2-rights {
  color: white;
  position: relative;
  display: inline-flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  text-align: start;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 0.83vw;
  /*font-size: 12px;*/
  line-height: 120%;

  letter-spacing: 0.01em;
}

div.footer-row-2-social {
  color: white;
  position: relative;
  display: inline-flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: flex-end;
  text-align: end;
}

img.footer-social {
  /*width: 48px;*/
  /*height: 32px;*/
  height: 2.22vw;
}
img.footer-logo {
  height: 3.33vw;
  width: fit-content;
}

.footer-social-padding {
  padding-left: 16px;
}

@media only screen and (max-width: 767.9px) {
  div.footer-row-2-rights {
    padding-top: 40px;
    padding-bottom: 24px;
    font-size: 12px;
  }
  .paddingDesktopTop16 {
    padding-top: 0px;
  }
}

.paddingTop16 {
  padding-top: 16px;
}

.paddingDesktopTop16 {
  padding-top: 16px;
}

.footer-menu-item {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  /*font-size: 12px;*/
  font-size: 0.83vw;
  line-height: 120%;
  /* or 14px */

  letter-spacing: 0.01em;
}
