@import "App";

.description {
    color: var(--color-text-primary);
    margin-bottom: 40px;
    padding-top: 90px;
    position: relative;

    h2 {
        @include medium-heading3;
        color: var(--color-text-primary);
        margin-bottom: 40px;
    }
    h3 {
        @include large-text;
        color: var(--color-text-primary);
        margin-bottom: 40px;
    }
    p, li {
        @include medium-text;
        margin-bottom: 40px;
    }

    label {
        display: block;
    }

    &Editor {
        margin: 0;
        position: absolute;
        right: 0;
    }
}

.flex {
    display: flex; 
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}