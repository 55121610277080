
@import "App";

.fullScreen {
    cursor: pointer;
    display: flex;
    gap: 20px;
    align-items: center;
    margin-bottom: 10px;

    svg:hover{
        color: var(--color-text-primary-active);
    }
}

.blockTitle {
    @include large-text;
    margin-bottom: 10px;
    flex-grow: 1;
}

@mixin map-base {
    .mapContent {
        background: var(--background-primary-light);
        padding: 20px 30px 10px;
    }

    .mapContentHeader {
        display: inline-flex;
        flex-direction: row;
        width: 100%;
    }

    & > div:last-child {
        max-height: 400px;
        max-width: 100%;
    }
}

.map {
    @include map-base;
}

.mapfs {

    @include map-base;

    & > div:last-child {
        max-height: calc(100vh - 65px);
        max-width: 100%;
    }
}
  