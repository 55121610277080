@import "App";

.item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    div {
        cursor: pointer;
        color: var(--color-text-primary);
        margin: 0 10px 0 0;
    }

    &.new div {
        font-weight: bold;
    }
}

.blockTitle {
    @include large-text;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
        color: var(--color-text-primary-active);
    }

    span {
        @include medium-text;
    }
   
}

.label {
    min-width: 70px;
    display: inline-block;
}