@import "App";


.title {
    @include medium-heading3;
    color: var(--color-text-primary);
    margin: 30px 0;
}

.form {
  max-width: 400px;
}
