div.contact-us-col {
  display: flex;
  flex-direction: column;
  width: 100%;
}

div.contact-us-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  background: #ffffff;
  background-size: cover !important;
  background-image: url("../../../assets/svgs/background2.svg");
  box-shadow: 9.97701px 14.9655px 39.908px rgba(130, 130, 130, 0.05);
  align-items: stretch;
  /*width: 100%;*/
  /*max-width: 1440px;*/
  min-height: 612px;
  padding-top: 91px;
  padding-bottom: 90px;
}
div.contact-us-info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 50%;
}

.contact-us-heading {
  color: #02163d;
  text-align: start;
}

div.contact-us-form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 50%;
}

div.form-col {
  display: inline-flex;
  flex-direction: column;
  flex-grow: 1;
  width: 50%;
  text-align: start;
  padding-left: 20px;
  padding-bottom: 16px;
  color: #292929;
}

div.form-row {
  display: flex;
  flex-direction: row;
  text-align: start;
}

div.ll-message {
  padding-left: 20px;
  padding-bottom: 16px;
}
/* override ant input style */

.ant-input {
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
  border-width: 2px;
}

.ant-input:focus {
  border-color: #cea63e;
  border-width: 2px;
  box-shadow: none;
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
}

.ant-input:hover {
  border-color: #cea63e;
  border-width: 2px;
  box-shadow: none;
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
}

.ant-radio-wrapper {
  top: -14px;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #011d53 !important ;
  border: 6px solid;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: transparent;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #cea63e;
}

@media only screen and (max-width: 767.9px) {
  div.contact-us-container {
    display: none;
  }
  .contact-us-heading {
    display: block;
    padding-top: 56px;
    padding-bottom: 40px;
  }
  .ant-input {
    padding-top: 24px;
    padding-left: 0px;
  }
  .ant-row {
    flex-grow: 1;
  }
}

@media only screen and (min-width: 768px) {
  div.contact-us-mobile-container {
    display: none !important;
  }
}

div.form-col-mobile {
  display: inline-flex;
  flex-direction: column;
  flex-grow: 1;
}

div.contact-us-mobile-container {
  display: flex;
  flex-direction: column;
  text-align: start;
  padding-bottom: 172px;
  background-image: url("../../../assets/svgs/background1.svg");
  background-repeat: no-repeat;
  background-size: auto 400px;
  background-position-y: bottom;
  background-position-x: 100px;
}

/* a {
  color: #02163d !important;
} */
