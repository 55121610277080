@import "~antd/dist/antd.css";
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* common styles*/

/* fonts */
/* Body L/Regular */

.lotline-font-regular {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 120%;
  /* identical to box height, or 24px */

  letter-spacing: 0.01em;
}

.lotline-font-regular-w {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  /*font-size: 20px;*/
  font-size: 1.39vw;
  line-height: 120%;
  /* identical to box height, or 24px */

  letter-spacing: 0.01em;
}

.lotline-font-regularM {
  /* Body M/Regular */

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 120%;
  /* or 22px */

  letter-spacing: 0.01em;
}

.lotline-font-regularM-w {
  /* Body M/Regular */

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 1.25vw;
  line-height: 120%;
  /* or 22px */

  letter-spacing: 0.01em;
}


.lotline-font-header2 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 64px;
  line-height: 110%;
  /* or 70px */

  text-align: center;
}

.lotline-font-header2-w {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 4.4vw;
  line-height: 110%;
  /* or 70px */

  text-align: center;
}

.lotline-font-header3 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 110%;
}

.lotline-font-header3-w {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  /*font-size: 32px;*/
  font-size: 2.2vw;
  line-height: 110%;
}

.lotline-font-header3L {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 130%;
}

.lotline-font-header3L-w {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 2.2vw;
  line-height: 130%;
}

.lotline-font-header-light {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 100px;
  line-height: 110%;
}

.lotline-font-header-light-w {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  /*font-size: 100px;*/
  font-size: 6.9vw;
  line-height: 110%;
}

.lotline-font-header-medium-w {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  /*font-size: 100px;*/
  font-size: 6.9vw;
  line-height: 110%;
}

.lotline-font-buttonL-w {
  color: #02163d !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  letter-spacing: .05em;
  line-height: 120%;
  text-transform: uppercase;
}

@media only screen and (max-width: 767.9px) {
  .lotline-left-border {
    /*padding-left: 155px;*/
    padding-left: 24px;
  }
  .lotline-right-border {
    /*padding-right: 155px;*/
    padding-right: 24px;
  }

  .lotline-font-regularM-w {
    font-size: 18px;
  }

  .lotline-font-regular-w {
    font-size: 20px;
  }
  .lotline-font-header2 {
    font-size: 40px;
  }

  .lotline-font-header2-w {
    font-size: 40px;
  }

  .lotline-font-header3L {
    font-size: 28px;
  }

  .lotline-font-header3L-w {
    font-size: 28px;
  }

  .lotline-font-header3 {
    font-size: 24px;
  }

  .lotline-font-header3-w {
    font-size: 24px;
  }

  .lotline-font-buttonL {
    font-size: 14px;
  }

  .lotline-font-buttonL-w {
    font-size: 14px;
  }
}


/* borders */
@media only screen and (min-width: 768px) {
  .lotline-left-border {
    /*padding-left: 155px;*/
    padding-left: 10.7%;
  }
  .lotline-right-border {
    /*padding-right: 155px;*/
    padding-right: 10.7%;
  }
}
