@import "App";

.title {
    @include large-bold-text;
    color: var(--color-text-primary);
    margin: 60px 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.block {
    padding: 0 20px 30px 0;
}

.grid {
    display: flex;
    flex-direction: column-reverse;

    @include  media-up($md) {
        display: grid;
    }
}

.adminPage label {
    color: var(--color-text-primary) !important;
}

.form {
    margin: 30px 0;
}

.table {
    position: relative;
    width: 20vw;

    & > div {
        min-width: 95vw;
    }
}

.filter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
}

amplify-s3-image.image {
    --height: 20px;
    --width: auto;
}

.btn {
    margin-right: 15px;
}

.btns {
    display: flex;
    gap: 20px;
}
