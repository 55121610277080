@import "App";

.card {
  background: var(--background-primary-light);
  display: flex;
  flex-grow: 1; 
  flex-direction: column;
  align-items: center;
  padding: 23px;

  &Big {
    padding: 50px 40px;
  }
}

.mainTitle {
  color: $gold;
  @include medium-heading3;
  margin-bottom: 10px;
}

.mainText {
  @include medium-text;
  margin-bottom: 10px;
}

.title {
  color: $gold;
  @include large-text;
  margin-bottom: 10px;
}

.text {
  @include small-text;
  margin-bottom: 10px;
}

.data {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  width: 100%;
}

.dataRow {
  @include small-text;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.dataName {
  color: $grey;
  padding-right: 20px;
}