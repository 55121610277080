
@import "App";

.lotline_menu {
    background: var(--background-primary-light);
    font-family: Roboto;
    font-size: 1em !important;
    font-style: normal;
    font-weight: 700;
    letter-spacing: .05em;
    line-height: 2;
    text-transform: uppercase;

    a {
        color: var(--color-text-primary) !important;
    }

    svg {
        width: 24px;
        height: 24px;
        margin: 6px 0 0;
    }
  
}

.name {
    cursor: default !important;
    text-transform: capitalize;
    &:hover {
        color: #9AA2B1 !important;
    }
}
  
.icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25px !important;
    font-size: 11px !important;
    
    :global(.ant-menu-submenu-popup) & {
        margin: 0 !important;
        align-items: flex-start;
        line-height: 18px;
    }
}
  