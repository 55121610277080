div.product-container {
  display: inline-flex;
  flex-direction: column;
  column-gap: 16px;
  flex-grow: 1;
}

div.product-img {
  display: inline;
  width: 100%;
}

div.product-index {
  color: #cea63e;
  text-align: start;
  /*font-family: Roboto;*/
  /*font-style: normal;*/
  /*font-weight: 500;*/
  /*font-size: 32px;*/
  /*line-height: 110%;*/
  padding-top: 8px;
  /* or 35px */
}

div.product-text {
  color: white;
  text-align: start;
}

img.product-image {
  width: inherit;
}

@media only screen and (max-width: 767.9px) {
  div.product-index {
    padding-top: 24px;
  }
  div.product-text {
    padding-bottom: 32px;
  }
}
