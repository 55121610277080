:root {
  --color-text-primary: #02163d;
  --background-primary-light: #fff;
  --background-primary-middle: #fff;
  --background-primary-dark: #e5e5e5;
  --color-text-primary-active: #cea63e;



  --amplify-colors-background-primary-light: #fff;
  --amplify-colors-background-primary-dark: #e5e5e5;
  --amplify-colors-text: #02163d;
  --max-width: 1350px;

}

[data-amplify-color-mode='dark'] {
  --color-text-primary: #fff;
  --background-primary-light: #152039;
  --background-primary-middle: #111A2E;
  --background-primary-dark:#060E1E;
  --color-text-primary-active: #cea63e;


  --amplify-colors-background-primary-light: #02163D;
  --amplify-colors-background-primary-dark: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), #02163D;
  --amplify-colors-text: #fff;
}

html, body {
  background: var(--background-primary-dark);
  height: 100%;
  text-rendering: auto;
}


.ant-typography h2,  
.ant-typography h3,
.ant-typography h4,
.ant-typography h5,
.ant-typography h6,
h2, h3, h4, h5, h6 {
  color: var(--color-text-primary);
}

#scrollableDiv::-webkit-scrollbar {
  width: 8px; 
  height: 8px;
}

#scrollableDiv::-webkit-scrollbar-track {
  background: var(--amplify-colors-background-primary-dark);
}

#scrollableDiv::-webkit-scrollbar-thumb {
  background-color: var(--amplify-colors-background-primary-light);
  border-radius: 5px; 
  border: 3px solid var(--amplify-colors-background-primary-dark);
}


.wrap {
  background: var(--background-primary-dark);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  text-align: center;
}

.content {
  color:  var(--color-text-primary);
  flex-grow: 1;
  padding: 0 20px;
  text-align: left;
  margin: 0 auto;
  max-width: var(--max-width);
  width: 100%;
}

.flexGrow {
  flex-grow: 1;
}


/* Auth page */
[data-amplify-authenticator] {
  background: #ffffff;
  background-position-x: right;
  background-size: auto;
  background-repeat: no-repeat;
  background-image: url("./assets/svgs/background3.svg");
  display: flex;
  justify-content: center;
  height: 100%;
  min-height: 100vh;

  --amplify-components-tabs-item-active-color: #cea63e;
  --amplify-components-tabs-item-active-border-color: #cea63e;
  --amplify-components-authenticator-router-box-shadow: none;
  --amplify-components-authenticator-router-border-color: transparent;
  --amplify-components-tabs-item-color: #9aa2b1;
  --amplify-components-authenticator-state-inactive-background-color: #f2f2f2;
  --amplify-components-tabs-item-border-color: #f2f2f2;
  --amplify-components-authenticator-router-border-width: 0;
  --amplify-components-fieldcontrol-border-color: #02163d;
  --amplify-components-fieldcontrol-border-radius: 0;
  --amplify-components-button-color: #cea63e;
  --amplify-components-button-link-color: #cea63e;
  --amplify-components-button-primary-background-color: transparent;
  --amplify-components-button-primary-border-color: #02163d;
  --amplify-components-button-primary-color: #02163d;
  --amplify-components-button-primary-hover-background-color: #cea63e;
  --amplify-components-button-hover-background-color: #cea63e;
  --amplify-components-button-hover-border-color: #02163d;
  --amplify-components-button-hover-color: #fff;
  --amplify-components-tabs-item-hover-color: #cea63e;
  --amplify-components-tabs-item-focus-color: #cea63e;
  --amplify-components-radio-button-checked-color: #cea63e;
  --amplify-components-radio-button-border-color: #9aa2b1;
  --amplify-components-fieldcontrol-focus-border-color: #9aa2b1;
  --amplify-colors-border-focus: #cea63e;
  --amplify-components-authenticator-router-background-color: rgba(255, 255, 255, 0.7);

}

[data-amplify-authenticator] input[name="custom:builder"] {
  display: none;
}
[data-amplify-authenticator] .amplify-input {
  text-align: left;
}

[data-amplify-authenticator] .amplify-input::placeholder {
  color: #9aa2b1;
}

[data-amplify-authenticator] .amplify-button[data-variation="primary"] {
  padding: 20px;
  text-transform: uppercase;
  margin-top: 20px;
}

[data-amplify-authenticator] .logo {
  max-height: 4em;
}



.ant-breadcrumb {
  background: var(--background-primary-dark);
  margin: -10px auto 0;
  max-width: var(--max-width);
  padding: 20px 0 10px;
  position: fixed;
  width: 100%;
  z-index: 4;
}
.ant-breadcrumb,
.ant-breadcrumb-separator,
.ant-breadcrumb-link,
.ant-breadcrumb .ant-breadcrumb-link a {
  color: #9AA2B1;
}
.ant-breadcrumb li:last-child .ant-breadcrumb-link {
  color: var(--color-text-primary-active);
}

.breadcrumbWrapper {
  background: var(--background-primary-dark);
  position: fixed;
  left: 0;
  z-index: 4;
  width: 100vw;
}

.breadcrumbContent {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;
  margin: 0 auto;
  max-width: var(--max-width);
}

.breadcrumbWrapper .ant-breadcrumb {
  flex: 1;
  position: relative;
  width: auto;
}

/* Menu */
.ant-menu-horizontal {
  border: none;
}
.ant-menu-horizontal > .ant-menu-submenu,
.ant-menu-horizontal > .ant-menu-item {
  display: flex;
  align-items: center;
}
.ant-menu-overflow-item-rest {
  margin-left: auto;
}
.ant-menu-horizontal .ant-menu-item::after,
.ant-menu-horizontal .ant-menu-submenu::after {
  border-bottom: none !important;
}

.ant-menu-vertical .ant-menu-item {
  height: auto;
}

.ant-menu-sub.ant-menu-inline {
  background-color: var(--background-primary-middle) !important;
}
.ant-menu-item:active, .ant-menu-submenu-title:active {
  background: var(--background-primary-middle) !important;
}

.ant-menu,
.ant-menu-item a {
  color: #9AA2B1 !important;
  background: transparent;
}
.ant-menu-item:hover a,
.ant-menu-item-active a,
.ant-menu-item-selected a,
.ant-menu-submenu-selected,
.ant-menu-submenu:hover,
.ant-menu-submenu-title:hover,
.ant-drawer-close,
.ant-menu-submenu-expand-icon, 
.ant-menu-submenu-arrow {
  color: var(--color-text-primary) !important;
}
.ant-menu-submenu-popup > .ant-menu {
  background-color: var(--background-primary-light) !important;
}
.ant-menu .ant-menu-item-selected {
  background-color: transparent !important;
}
.ant-menu-horizontal > .ant-menu-item:last-child, 
.ant-menu-horizontal > .ant-menu-submenu:last-child {
  padding-right: 0;
}

.ant-drawer-content, 
.ant-drawer-header {
  background: var(--background-primary-light) !important;
}

.ant-progress-inner {
  background: rgba(206, 166, 62, 0.4);
  border-radius: 0;
}

a:hover {
  color: var(--color-text-primary-active);
}

.notifications .ant-alert {
  border: none;
  background: none;
  padding: 8px 0;
}
.notifications .ant-alert-message,
.notifications .ant-alert-close-icon .anticon-close {
  color: var(--color-text-primary);
}
.notifications .ant-alert-close-icon .anticon-close:hover,
.news .ant-list-item-meta-title a:hover {
  color: var(--color-text-primary-active) !important;
}

.news .ant-list-item,
.news .ant-list-item-meta-title a,
.news .ant-list-item-meta-description,
.ant-typography,
.ant-form-item-label > label {
  color: var(--color-text-primary) !important;
}

.maplibregl-popup-close-button {
  color: var(--color-text-primary-active) !important;
  outline: none;
}

.mapboxgl-popup-content {
  color: var(--color-text-primary);
  background: var(--background-primary-light);
  padding: 20px;
}

.marker {
  height: auto !important;
  display: block !important;
}

.marker .mapboxgl-popup-content {
  background: transparent;
  padding: 0;
}

.marker .maplibregl-popup-close-button {
  display: none;
}

.market .ant-carousel .slick-dots-bottom {
  bottom: -10px;
}
.market .ant-carousel .slick-slider {
  padding-bottom: 30px;
}
.ant-progress-text {
  color: var(--color-text-primary);
}
.fundingProcess .ant-progress-text {
  color: #16C95B;
}

.fundingProcess .ant-progress-steps-item {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
  min-width: 15%;
}
.fundingProcess .ant-progress-steps-item-active::after {
  content: '';
  background: #16C95B;
  border-radius: 50%;
  display: block;
  height: 8px;
  width: 8px;
}

.market .ant-progress-line {
  width: 99%;
}
.ant-radio-button-wrapper {
  box-shadow: none !important;
  color: var(--color-text-primary);
  background: transparent;
  border-color: var(--color-text-primary)!important;
  height: 50px;
  display: inline-flex;
  align-items: center; 
  padding: 0 20px;
}

.ant-radio-button-wrapper:hover {
  background: var(--color-text-primary-active) !important;
  color: var(--color-text-primary) !important;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: var(--color-text-primary-active);
}

.ant-select-selector {
  color: var(--color-text-primary) !important;
  background: transparent !important;
  min-height: 50px !important;
  display: flex;
  align-items: center;
}

.ant-select-selection-overflow {
  max-height: 50px;
  overflow-x: auto;
}

.ant-select-selection-item,
.ant-select-selection-item-remove,
.ant-input,
.ant-input-affix-wrapper,
.ant-input-affix-wrapper-disabled {
  color: var(--color-text-primary) !important;
  background: transparent !important;

}
.ant-input {
  border-width: 1px !important;
  border-style: solid !important;
  border-color: var(--color-text-primary);
  padding: 13px 10px;
}

.ant-input-affix-wrapper .ant-input {
  border-color: transparent !important;
}
.ant-select-selector:hover {
  border-color: var(--color-text-primary-active) !important;
}
.ant-select-selector .ant-select-selection-search-input {
  height: 50px !important; 
}
.ant-select-arrow {
  color: var(--color-text-primary) !important;
}
.ant-select-dropdown {
  background: var(--background-primary-light)
}
.ant-select-item,
.ant-modal-close-x {
  color: var(--color-text-primary);
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled),
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: var(--background-primary-dark);
  color: var(--color-text-primary);
}

.ant-popover-inner,
.ant-popover-arrow-content,
.ant-modal-content,
.ant-modal-header {
  background: var(--background-primary-dark);
}
.ant-popover-inner-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ant-checkbox-group-item {
    display: flex;
    margin: 0 0 10px 0;
}

.ant-popover-message,
.ant-modal-confirm-body .ant-modal-confirm-content,
.ant-form-item-control-input-content,
.ant-modal-title,
.ant-modal-body,
.ant-checkbox-wrapper {
  color: var(--color-text-primary);
}

.ant-modal-mask {
  background-color: rgba(206, 166, 62, 0.2);
}

.ant-btn {
  background: transparent;
  border: 2px solid var(--color-text-primary);
  color: var(--color-text-primary);
  text-transform: uppercase;
}
.ant-btn-primary {
  background: transparent;
  border-color: var(--color-text-primary-active); 
  color: var(--color-text-primary-active);
}
.ant-btn:hover {
  background: var(--color-text-primary);
  border-color: var(--color-text-primary); 
  color: var(--background-primary-dark);
}
.ant-btn-primary:hover {
  background: var(--color-text-primary-active);
  border-color: var(--color-text-primary-active); 
}

amplify-s3-image {
  --height: 100%;
  --width: 100%;
}
.ant-form-item .ant-upload.ant-upload-drag {
  width: 195px;
  height: 104px;
  display: inline-block;
}
.ant-form-item-control-input-content > span {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 5px;
}
.ant-upload.ant-upload-drag .ant-upload {
  background: var(--background-primary-dark);
  padding: 5px 0;
}
.ant-upload.ant-upload-drag p.ant-upload-text {
  color: var(--color-text-primary);
  font-size: 14px;
  line-height: 16px;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: var(--color-text-primary-active);
}

.investorsPage .ant-carousel .slick-dots-bottom {
  bottom: 0;
}

.jodit_theme_dark .jodit-wysiwyg,
.jodit_theme_dark .jodit-toolbar__box:not(:empty),
.jodit-wysiwyg * {
  background: var(--background-primary-dark) !important;
  color: var(--color-text-primary);
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 20px 24px;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  color: var(--color-text-primary);
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  margin-right: 0;
  font-size: 18px;
  vertical-align: -5px;
  color: #8B95AE;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 !important;
}

.ant-tabs,
.ant-divider-horizontal.ant-divider-with-text {
  color: var(--color-text-primary);
}

.ant-divider-horizontal.ant-divider-with-text {
  border-color: var(--color-text-primary);
}
.ant-tabs-tab:hover,
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--color-text-primary-active);
}
.ant-tabs-ink-bar {
  background: var(--color-text-primary-active);
}