@import "App";

.title {
    @include large-bold-text;
    margin: 40px 0 20px;
}

.name {
    text-transform: capitalize;
}

.buttongold {
    @include gold-button;
}

.icon {
    color: $gold;
}

.form {
    max-width: 400px;

    label {
        height: 100%;
    }
} 

.print {
    margin: 60px 0 40px; 
}

.statementForm {
    max-width: 600px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    & > div {
        flex-grow: 1;
    }

    button {
        margin-bottom: 24px;
        height: 50px;
    }
}

.iconBtn svg {
    cursor: pointer;
    height: 20px;
    margin: 0 5px;
    width: 20px;
}

.sortTag {
    position: absolute;
    width: 11px;
    height: 12px;
    margin: 0;
    line-height: 10px;
    padding: 0;
    right: -15px;
    top: -8px;
}
