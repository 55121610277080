@import "App";

.projectPage {
    width: 90%;
    max-width: 1320px;
    margin: 0 auto;
    padding-bottom: 50px;
    position: relative;
    overflow: hidden;

    :global(.ant-table) {
        margin-bottom: 0;
    }
}

.project {
    display: flex;
    flex-direction: column;
    padding: 60px 0;

    @include media-up($md) {
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
    }

    &_info {
        overflow: hidden;
        position: relative;
       
        @include media-up($md) {
            width: 60%;
        }
    }

    &_details {
        @include media-up($md) {
            width: 35%;
            margin-left: 115px;
            padding-bottom: 20px;
        }
    }
}



.title {
    @include light-heading;
    color: var(--color-text-primary);
    margin-bottom: 15px;

    span {
        @include medium-heading;
    }
}

.numbers {
    @include medium-heading3;
    margin-bottom: 15px;

    &_dscr {
        @include large-text;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 5px;
    }
}

.progress {
    top: -10px;
    margin-bottom: 30px;
}

.dscr {
    @include medium-text;
    border-top: 1px solid #FFFFFF;
    padding: 20px 0 40px;
}

.btnDscr {
    @include small-text;
    color: $grey;
    margin-top: 10px;
    text-align: center;
}

.block {
    display: flex;    
    flex-direction: column;
    gap: 8px;
    margin-bottom: 8px;

    @include media-up($md) {
        flex-direction: row;
    }

    &Col {
        display: flex;
        flex-direction: column;
        gap: 8px;
        min-width: 45%;
        flex-grow: 1;

        &30 {
            min-width: 30%;  
        }

        &50 {
            
            @include media-up($md) {
                max-width: 45%;
            }
        }
    }

    &Item {
        background: var(--background-primary-light);
        color: var(--color-text-primary) !important;
        flex: 1;

        &:global(.ant-collapse-item:not(.ant-collapse-item-active)) {
            flex: 0;
        }

        &Transparent {
            background-color: transparent;
        }
        
        td {
            text-align: left !important;
            padding: 12px 24px !important;

            &:first-child {
                color: $grey !important;
            }
        }

        h3 {
            @include large-text;
            color: var(--color-text-primary) !important;
            margin-bottom: 0;
            flex-grow: 1;
        }

        .content {
            padding: 20px 24px;
        }
    }
}


.card {
    @include medium-text;

    p {
        @include small-text;
        color: $grey;
        margin-top: 24px;
    }
}

.map {
    flex: 0;

    & > div > div > div:last-child {
        max-width: 100%;
        height: 100%;
        max-height: none;
    }
}

.chart {
    min-height: 400px;
    position: relative;

    .content {
        position: absolute;
        width: 100%;
        height: calc(100% - 40px);
    }
}

.fundingProcess {
    width: 250px;
}

.flex {
    display: flex; 
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.saveBtn {
    display: flex;
    gap: 10px;

    a {
        padding: 7px 20px !important;
    }
}

.form {
    color: var(--color-text-primary);

    // & + div,
    & + form {
        padding-top: 90px;
    }

    :global(.ant-select-selection-overflow) {
        max-height: none;
    }
}

.statuses {
    margin: 60px 0 -20px;
    max-width: 700px;
    width: 60%
}

.range {
    :global(.ant-slider-track),
    &:hover :global(.ant-slider-track) {
        background-color: var(--color-text-primary-active) !important;
    }
    
    :global(.ant-slider-handle),
    &:hover :global(.ant-slider-handle) {
        border-color: var(--color-text-primary-active) !important;
    }
}