.lotline-background {
  position: absolute;
  flex-direction: row;
  /*top: calc(10%);*/
  bottom: 0;
  right: -50px;
  transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
  z-index: 1;
  /*aspect-ratio: 2/1;*/
}
.lotline-background-1 {
  display: block;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    72.02% 34.98% at 50% 50%,
    #000000 0%,
    rgba(0, 0, 0, 0) 100%
  );
  opacity: 0.05;
}

.lotline-over {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.lot-row {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
}
.lot {
  border: 4px rgba(00, 00, 00, 0.1) solid;
  margin: 8px;
  flex-grow: 1;
  border-radius: 16px;
  /*aspect-ratio: 2/1;*/
}

.shadow {
  position: absolute;
  z-index: -1;
  border: 4px rgba(00, 00, 00, 0.1) dashed;
  border-radius: 16px;
  margin: 8px;
  opacity: 0.5;
}

.awfull {
  width: 100%;
  height: 100%;
  background-image: url("../../../assets/svgs/parallax/gold-house.svg");
  transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0)
    matrix(0.71, 0.41, -1.22, 0.71, 0, 0) matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
}

.awfull1 {
  width: 100%;
  height: 100%;
  transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0)
    matrix(0.71, 0.41, -1.22, 0.71, 0, 0) matrix(0.71, 0.41, -1.22, 0.71, 0, 0)
    matrix(0.71, 0.41, -1.22, 0.71, 0, 0) matrix(0.71, 0.41, -1.22, 0.71, 0, 0)
    matrix(0.71, 0.41, -1.22, 0.71, 0, 0) matrix(0.71, 0.41, -1.22, 0.71, 0, 0)
    translate(0px, -15%);
}

.lot-gold {
  background-color: #fafafa;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23CEA63EFF' stroke-width='4' stroke-dasharray='16%2c 16' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 16px;
  margin: 8px;
  flex-grow: 1;
  will-change: transform;
  transform: translateX(-45.5197%) translateY(-64%);
  transition: transform 1s ease-out 100ms
}
.lotline-background:hover .lot-gold {
  transform: translateX(0) translateY(-5%);
}
