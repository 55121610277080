div.project-card {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  /*width: 553px;*/
  /*height: 260px;*/
  /*padding-right: 20px;*/
  text-align: start;
  padding-top: 40px;
  /*row-gap: 16px;*/
}

span.pc-count {
  color: #cea63e;
}

.swiper-slide {
  width: 40%;
  display: inline-flex;
  flex-direction: column;
}

img.project-card-img {
  width: 100%;
  height: auto;
}

div.card-count {
  padding-top: 16px;
}

div.card-location {
  padding-top: 20px;
}

div.card-state {
  padding-top: 8px;
}

@media only screen and (min-width: 768px) {
  div.project-card {
    width: 85%;
    padding: 0;
  }
}
