div.lotline-left {
  position: absolute;
  top: -20px;
  left: 0px;
  width: 10px;
  height: 15px;
  background-position: center;
  background-size: cover;
  background-image: url("../../../assets/svgs/toLeft.svg");
}

div.lotline-right {
  position: absolute;
  top: -20px;
  left: 20px;
  width: 10px;
  height: 15px;
  background-position: center;
  background-size: cover;
  background-image: url("../../../assets/svgs/toRight.svg");
}

div.projects-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #e5e5e5;
}

div.projects-img-container {
  display: inline-block;
}

div.projects-opinion-container {
  display: inline-flex;
  flex-direction: row;
  background-color: transparent;
}

div.projects-opinion {
  display: inline-flex;
  position: relative;
  flex-direction: column;
  background-color: #02163d;
  width: 100%;
  top: -16.14vw;
  min-height: 32.27vw;
  padding: 5.56vw;
}

.swiper-button-next {
  right: 0px;
}

.swiper-button-prev {
  left: 0px;
}

div.projects-opinion-mobile {
  display: inline-flex;
  position: relative;
  flex-direction: column;
  background-color: #02163d;
  width: 100%;
  top: -100px;
  min-height: 510px;
  padding: 56px 40px;
}

div.projects-inner-container {
  display: inline-flex;
  flex-direction: column;
  flex-wrap: nowrap;
  row-gap: 48px;
  padding-bottom: 5.56vw;
}

div.project-title {
  /*padding-top: 104px;*/
  text-align: start;

  /* Heading 2 / Light */

  color: #02163d;
}

img.projects-img {
  max-width: 100%;
  height: auto;
}

.swiper {
  width: 100%;
  height: 100%;
  --swiper-theme-color: #cea63e;
  --swiper-pagination-bullet-inactive-color: #cea63e;
  --swiper-pagination-bullet-inactive-opacity: 0.5;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

div.project-slider {
  align-items: center;
  display: flex;
  flex-direction: column;
  row-gap: 2.22vw;
  color: white;
}

div.project-slider-header {
  color: white;
}

div.project-slider-txt {
  padding-left: 2.78vw;
  padding-right: 2.78vw;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  /*font-size: 36px;*/
  font-size: 2.5vw;
  line-height: 130%;
  /* or 47px */

  text-align: center;

  color: #ffffff;
}

div.project-slider-delimiter {
  width: 10%;
  height: 0;
  border: solid 1px white;
  margin-top: 2.22vw;
}

div.projects {
  display: flex;
  flex-direction: row;
  width: 100%;
}

@media only screen and (max-width: 767.9px) {
  div.project-slider {
    row-gap: 32px;
  }
  div.projects-opinion {
    display: none;
  }
  div.project-slider-txt {
    font-size: 20px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .swiper-slide {
    -webkit-justify-content: flex-start;
    justify-content: start;
  }

  .desktop-carousel {
    display: none;
  }
  img.projects-img {
    height: 337px;
    width: auto;
    /*max-width: fit-content;*/
    object-fit: cover;
  }
  div.projects-inner-container {
    row-gap: 0px;
    padding-bottom: 40px;
  }
  .swiper {
    display: inline-flex;
    flex-direction: column;
    flex-grow: 1;
  }
  div.project-slider-txt {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media only screen and (min-width: 768px) {
  div.projects-opinion-mobile {
    display: none;
  }
  .mobile-carousel {
    display: none;
  }
}
