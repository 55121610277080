.ant-table {
    background: transparent;
    color: var(--color-text-primary);
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 13px;
    line-height: 120%;
    letter-spacing: 0.01em;
    overflow-x: auto;
    margin-bottom: 30px;
}
.ant-spin-container::after {
    background: transparent;
}
.ant-table table {
    border-spacing: 0 3px;
}
.ant-table-thead > tr > th {
    background: var(--background-primary-light);
    color: var(--color-text-primary);
    font-weight: 600;
}
.ant-table-row {
    background: var(--background-primary-middle);
}
.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    padding: 10px;
}

tr.ant-table-expanded-row > td,
tr.ant-table-expanded-row:hover > td {
    background: transparent;
}

.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr.ant-table-placeholder:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: transparent;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
    background: var(--background-primary-light);
}

.simpleTable {
    border-spacing: 0;
    max-width: 800px;
}
.simpleTable .ant-table {
    overflow: hidden;
}
.simpleTable td {
    border: 1px solid var(--background-primary-dark) !important;
    padding: 6px !important;
    font-size: 12px!important;
}

.ant-table-summary > tr > td,
.ant-table-tbody > tr > td,
.ant-table-thead > tr > th {
    border: none;
    text-align: center;
}

.ant-table-summary > tr > td,
.ant-table-tbody > tr > td {
    text-align: left;
}

.ant-empty-description {
    color: white;
}
.ant-select-clear {
    background: transparent;
    color: var(--color-text-primary);
}
.ant-table-cell-fix-left, .ant-table-cell-fix-right {
    background: var(--background-primary-light) !important;
}

.ant-pagination {
    padding: 20px 0;
    text-align: right;
}
.ant-pagination-item,
.ant-pagination-prev .ant-pagination-item-link, 
.ant-pagination-next .ant-pagination-item-link {
    background: transparent;
    border-color: white;
    color: white;
}
.ant-pagination-item a,
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis, 
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: white;
}

.ant-pagination-item-active {
    font-weight: 500;
    background: transparent;
    border-color: #1890ff;
}

.ant-pagination-item-active a {
    color: #1890ff;
}
.ant-pagination-disabled .ant-pagination-item-link, 
.ant-pagination-disabled:hover .ant-pagination-item-link, 
.ant-pagination-disabled:focus-visible .ant-pagination-item-link {
    opacity: 0.6;
    color: white;
}

.status {
    background-color: #FF3600;
    border-radius: 50%;
    display: inline-block;
    height: 12px;
    width: 12px;
}

.status1 {
    background-color: #16C95B;
}

.ant-pagination-simple-pager,
.ant-pagination-simple .ant-pagination-simple-pager input {
    color: var(--color-text-primary);
    background: transparent;
}
td.ant-table-column-sort {
    background: transparent;
}
.ant-table-thead th.ant-table-column-sort {
    background: var(--background-primary-light);
}
.sortTag {
    position: absolute;
    width: 11px;
    height: 12px;
    margin: 0;
    line-height: 10px;
    padding: 0;
    right: -15px;
    top: -8px;
}
.icon {
    color: #cea63e;
}