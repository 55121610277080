/*
* Breakpoints
*/
$xs: 0px;
$md: 768px;
$lg: 1240px;
$xl: 1540px;

/*
* Media mixins
*/
@mixin media-up($media) {
    @media (min-width: #{$media}) {
        @content;
    }
}

@mixin media-down($media) {
    @media (max-width: #{strip-unit($media) - 1}px) {
        @content;
    }
}

/*
* Variables
*/
$dark: #02163D;
$gold: #cea63e;
$grey: #9AA2B1;

@mixin extra-small-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 120%;
    letter-spacing: 0.01em;
}

@mixin small-text {
    @include extra-small-text;
    font-size: 12px;
}

@mixin small-bold-text {
    @include extra-small-text;
    font-weight: 600;
}

@mixin medium-text {
    @include small-text;
    font-size: 16px;
}

@mixin large-text {
    @include medium-text;
    font-size: 18px;
}

@mixin large-bold-text {
    @include large-text;
    font-weight: 500;
}


.extra-small-text {
    @include extra-small-text;
}

.small-text {
    @include small-text;
}

.medium-text {
    @include medium-text;
}
.large-text {
    @include large-text;
}



@mixin light-heading {
    @include small-text;
    font-weight: 300;
    font-size: 34px;
    line-height: 110%;

    @include media-up($lg) {
        font-size: 44px;
    }
}

@mixin gold-button {
    background-color: var(--background-primary-light);
    border: 2px solid $gold;
    color: $gold;
    &:hover {
        background-color: var(--background-primary-light);
        border: 2px solid $gold;
        color: $gold;
    }
}

@mixin medium-heading {
    @include light-heading;
    font-weight: 500;
}

@mixin light-heading3 {
    @include light-heading;
    font-size: 20px;
    line-height: 130%;
    @include media-up($lg) {
        font-size: 25px;
    }
}

@mixin medium-heading3 {
    @include light-heading3;
    font-weight: 500;
}

@keyframes fade-in-kfs {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }