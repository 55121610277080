div.lotline-banner-container {
  display: flex;
  flex-direction: column;
  /*padding-left: 155px;*/
  /*padding-top: 105px;*/
  padding-top: 7.28vw;
  text-align: start;
  /*background-image: url("../imgs/background1.svg");*/
  /*background-repeat: no-repeat;*/
  /*background-position: top right;*/
  overflow: hidden;
}

div.banner-time-to-build {
  position: static;
  /*!*width: 719px;*!*/
  /*!*height: 234px;*!*/
  /*left: 0px;*/
  /*top: 0px;*/

  /* or 110px */

  color: #02163d;
}

@media only screen and (max-width: 767.9px) {
  div.banner-time-to-build {
    font-size: 46px;
  }
}

div.banner-more-houses {
  color: #cea63e;
}

div.banner-we-provide {
  /* Heading 3/Light */

  color: #02163d;
  width: 50%;
}

div.banner-button {
  padding-top: 6.94vw;
  padding-bottom: 13.88vw;
}

div.banner-button-mobile {
  padding-top: 240px;
  text-align: center;
  padding-bottom: 40px;
}

@media only screen and (min-width: 768px) {
  div.banner-button-mobile {
    display: none;
  }
}

@media only screen and (max-width: 767.9px) {
  div.banner-time-to-build {
    font-size: 46px;
  }
  div.banner-more-houses {
    font-size: 46px;
  }
  div.banner-we-provide {
    font-size: 20px;
    width: 100%;
  }
  div.banner-button {
    display: none;
  }
  div.lotline-banner-container {
    padding-top: 35px;
    background-size: cover;
  }
}

div.banner-button-mobile .ant-btn-primary,
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  border-color: #02163d;
  background-color: #02163d;
}

.background-container {
  display: inline-block;
}
