@import "App";


.title {
    @include medium-heading3;
    color: var(--color-text-primary);
    margin: 30px 0;
}

.form {
  label {
    min-width: 80px;
    height: 100%;
  }
  
  textarea {
    min-height: 120px;
  }
}
 
.buttons {
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
}