div.why-container {
  display: flex;
  flex-direction: row;
  background-color: #e5e5e5;
  padding-top: 104px;
  padding-bottom: 104px;
}

div.why-container-col {
  display: inline-flex;
  flex-direction: column;
  flex-grow: 1;
  row-gap: 24px;
  width: 50%;
}

.col-padding20 {
  padding-right: 20px;
}
div.why-hdr-txt {
  text-align: start;
  /* or 70px */
  color: #02163d;
  column-gap: 40px;
  padding-top: 143px;
}

div.why-txt {
  /* or 42px */
  color: #02163d;

  text-align: start;
  padding-bottom: 24px;
}

div.why-card {
  display: inline-flex;
  flex-direction: column;
  row-gap: 24px;
  background-color: white;
  width: 100%;
  /*min-height: 274px;*/
  text-align: start;
}

div.why-tst {
  display: inline-flex;
  flex-direction: column;
  row-gap: 24px;
  background-color: white;
  width: fit-content;
  /*min-height: 274px;*/
  text-align: start;
  padding: 40px;
}

div.why-item {
  text-align: start;
  /* or 35px */

  color: #02163d;
}

div.why-get-started {
  padding-top: 100px;
}

.why-ans-img {
  width: 7.2vw;
}

@media only screen and (max-width: 767.9px) {
  div.why-container {
    flex-direction: column;
    padding-top: 56px;
    padding-bottom: 64px;
  }
  div.why-container-col {
    width: 100%;
  }
  div.why-hdr-txt {
    padding-top: 0px;
  }
  div.why-get-started {
    text-align: start;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .why-ans-img {
    width: 104px;
  }
}
