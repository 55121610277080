div.products-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  background-color: #02163d;
  /*min-height: 703px;*/
  aspect-ratio: 2/1;
  width: 100%;
}

div.products-inner-container {
  display: inline-flex;
  flex-direction: column;
  flex-wrap: nowrap;
  background-color: #02163d;
  /*min-height: 703px;*/
  row-gap: 48px;
}

div.product-title {
  padding-top: 7.2vw;
  text-align: start;

  /* Heading 2 / Light */

  color: white;
}

div.products {
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  column-gap: 24px;
}

@media only screen and (max-width: 767.9px) {
  div.product-title {
    padding-top: 40px;
  }

  div.products {
    flex-direction: column;
  }
}
