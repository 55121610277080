@import "App";

.dashboardPage {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 40px 0;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 8px;

    @include media-up($md) {
        flex-direction: row;
    }
}

.leftBlock, .rightBlock {
    display: flex;
    flex-direction: column;
    gap: 8px;

    @include media-up($md) {
        width: 45%;
    }
}

.rightBlock {
    @include media-up($md) {
        flex-grow: 1;
        width: 55%;
    }
}

.chart {
    background: var(--background-primary-light);
    flex-grow: 1; 
    padding: 30px 40px;

    & > div {
        width: 100%;
    }
}

.notifications,
.market,
.block {
    width: 100%;
    display: flex;
    gap: 8px;
}

.notifications,
.market,
.block {
    background: var(--background-primary-light);
    flex-direction: column;
    padding: 30px 40px;
}

.column {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;

    :global(.ant-table) {
        margin: 0;
    }
}

.market {
    flex-grow: 1;
}

.blockTitle {
    @include large-text;
    margin-bottom: 10px;
    flex-grow: 1;

    &Link {
        color: var(--color-text-primary-active);
        margin: 0;
       
        &Center {
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }
    }
}

