@import "App";

.menu {
    border-bottom: 1px solid !important;
    background: var(--background-primary-dark) !important;
    font-family: Roboto;
    font-size: 1em !important;
    font-style: normal;
    font-weight: 700;
    justify-content: flex-start;
    letter-spacing: .05em;
    line-height: 3.5;
    text-transform: uppercase;
    position: absolute;
    width: 100%;
    z-index: 3;

    &.fixed {
        animation: fade-in-kfs 0.3s ease-in-out forwards;
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        padding: 0 20px;
        width: 100vw;
        top: 130px;

        & > div,
        & > ul {
            width: 90%;
            max-width: 1320px;
            margin: 0 auto;
        }
    }   



    & > li:first-child {
        padding-left: 0 !important;
    }

}

.formItem {
    margin: 0;
    width: 100%;

    & div + div {
        width: 100%;
        display: block;
    }
}

.checkboxes { 
    display: flex;
    max-width: 100%;
    gap: 0 20px;

    label {
        margin: 0;
        align-items: center;
        min-height: 40px;
        max-width: 14%;
        overflow: hidden;

        span {
            top: 0;
            padding: 0;
        }
        li {
            color: $grey !important;
            padding: 0 0 0 10px;
        }
    }
}