@import "App";

.form {
    max-width: 700px;

    &:global(.ant-form-horizontal .ant-form-item-label) {
        display: flex;
        align-items: center;
        // min-width: 200px;
        text-align: left;
    }

    :global(.ant-divider-inner-text) {
        display: flex;
        align-items: center;
        gap: 10px;
        
        div {
            margin: 0;
        }
    }

    .horizontal {
        margin-bottom: 5px;

        :global(.ant-checkbox-disabled + span) {
            color: inherit
        }
    }

    .sbmt {
        margin: 30px 0;
    }
}

.saveBtn {
    display: flex;
    gap: 10px;

    div {    
        border-color: var(--color-text-primary-active) !important;
    }
    a {
        color: var(--color-text-primary-active) !important;
        padding: 7px 20px !important;
    }
}


.title {
    @include large-bold-text;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 60px 0 20px;
    max-width: 700px;
}

.tab {
    @include media-up($md){
        display: flex;
        gap: 30px;
    }

    & > div {
        @include media-up($md){
            width: 50%;
        }
    }
}
