@import "App";

.title {
    @include large-bold-text;
    margin: 40px 0 20px;
}

.name {
    text-transform: capitalize;
}

.filter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
}
