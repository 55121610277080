@import "App";

.news {
    background: var(--background-primary-light);
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 30px 40px;
}

.content {
    height: 400px;
    overflow: auto;
}

.blockTitle {
    @include large-text;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    flex-grow: 1;

    svg {
        font-size: 15px;
    }
}

.description {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

amplify-s3-image.icon {
    display: flex;
    --height: 20px;
    --width: auto;

    &.nationalmortgagenews {
        margin-top: 3px;
    }
}

.btns,
.label {
    display: flex;
    gap: 20px;
}

.form {
    max-height: 50vh;
    overflow-y: auto;
}