@import "App";

.expandable {
  padding-left: 12px;
  padding-right: 36px;
  background-color: var(--background-primary-light);
  display: flex;
  flex-direction: row;
}

.column {
  display: inline-flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 24px;
}

.columnrow {
  display: inline-flex;
  flex-direction: row;
  gap: 30px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: solid 1px $grey;
}

.titlecol {
  font-weight: 600;
  text-align: left;
}

.namecol {
  display: inline-flex;
  flex-direction: column;
  flex-grow: 1;
  text-align: left;
}

.valuecol {
  display: inline-flex;
  flex-direction: column;
  min-width: 100px;
}
